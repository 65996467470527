



import { Component, Mixins } from 'vue-property-decorator'
import Challenge from '@/components/organisms/study/Challenge.vue'
import Contents4 from '@/views/student/study/Contents4.vue'

@Component({
  components: {
    Challenge,
  },
})
export default class Challenge7 extends Mixins(Contents4) {}
