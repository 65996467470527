












import { Component, Prop, Vue } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import RadioOkNg from '@/components/molecules/RadioOkNg.vue'

@Component({
  components: {
    ButtonBase,
    RadioOkNg,
  },
})
export default class Challenge extends Vue {
  @Prop()
  title?: string

  @Prop({ default: 0 })
  offset?: number

  @Prop()
  contents?: []
}
